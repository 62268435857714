.cursor-pointer {
  cursor: pointer;
}

.curser-default {
  cursor: default;
}

.primary-green {
  color: #a3ff12;
}

/* FONT SIZES  *********************************************** */

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.h4-5 {
  font-size: 2em;
}

.brighten-on-hover:hover {
  filter: brightness(.8);
}

.bg-dark-fade {
  background-color: rgba(0, 0, 0, 0.4);
}

.text-transform-none {
  text-transform: none;
}